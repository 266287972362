const firebaseConfig = {
  projectId: "the-rockefeller-v2",
  appId: "1:544020409383:web:50a6416c279f079dd2f202",
  databaseURL:
    "https://the-rockefeller-v2-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-rockefeller-v2.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyCjwx93QM7XHv9Crq5Ynvk8vbEjcc71YyI",
  authDomain: "the-rockefeller-v2.firebaseapp.com",
  messagingSenderId: "544020409383",
  measurementId: "G-GV8DQFTKCV",
};

export default firebaseConfig;
